import React, { useEffect, useState } from "react";
import classes from "./Search.module.css";
import searchIcon from "../../assets/icons/search.png";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";

const Search = (props) => {
  const [search, setSearch] = useState("");

  const searchHandler = (searchValue = props.jnumber) => {
    setSearch(searchValue);
  };

  useEffect(() => {
    props.searchHandler(search);
  }, [search]);

  return (
    <div className={classes.input}>
      <div className={classes.inner}>
        <input
          placeholder="search Jnumber"
          onChange={(e) => {
            props.searchHandler(e.target.value);
          }}
        />
        <Button>
          <img src={searchIcon}></img>
        </Button>
      </div>
    </div>
  );
};

export default Search;
