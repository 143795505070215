import "./App.css";
import { Fragment, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Footer from "./components/core/Footer";
import Header from "./components/Layout/Header";
// import Header from "./components/core/Header";
import Listview from "./pages/listview";
import Create from "./pages/create";
import Update from "./pages/update";
import Amplify, { Auth } from "aws-amplify";
import { currentConfig } from "./utilities/amplify-config";
import { checkAppAccessibility } from "./utilities/accessibility-check";
import PermissionError from "./components/error/permission-error/PermissionError";

Amplify.configure(currentConfig);

// function App() {
//   return (
//     <Fragment>
//       <Header></Header>
//       <Routes>
//         <Route path="/" element={<Listview />} />
//         <Route path="/create" element={<Create />} />
//         <Route path="/update/:id" element={<Update />} />
//       </Routes>
//       {/* <Update /> */}
//     </Fragment>
//   );
// }

function App() {
  const [userSession, setUserSession] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Authenicate User
    Auth.currentAuthenticatedUser()
      .then((userInfo) => {
        console.log("User", userInfo);
        setUser(userInfo.attributes);
      })
      .catch(() => {
        console.log("Not signed in redirecting to sign in");
        Auth.federatedSignIn();
      });

    // Get user session and save in state
    const getSession = async () => {
      await Auth.currentSession().then((userSession) => {
        setUserSession(userSession);
      });
    };
    getSession();
  }, []);

  // Check if user has permission to access the page
  const canUserAccess = () => {
    return checkAppAccessibility(user["custom:UserType"]);
  };

  // signout function
  const cognitoSignOut = () => {
    Auth.signOut();
  };

  // return application component when login is valid
  if (userSession && userSession?.isValid()) {
    return user && canUserAccess() ? (
      <div>
        <Header signout={cognitoSignOut} user={user}></Header>
         <Routes>
           <Route path="/" element={<Listview />} />
           <Route path="/create" element={<Create />} />
           <Route path="/update/:id" element={<Update />} />
         </Routes>
         <div>.</div>
        <Footer></Footer>
      </div>
    ) : (
      <PermissionError />
    );
  } else {
    // Navigate to signin if login is not valid
    return (
      <div>
        You will be automatically redirected or{" "}
        <a href="#" onClick={Auth.federatedSignIn}>
          click here to login
        </a>
      </div>
    );
  }
}

// function App() {
//   // console.log(process.env.REACT_APP_COGNITO_USERPOOL_ID);
//   // const [statusMessage, setStatusMessage] = useState("");
//   // const [tableData, setTableData] = useState({});
//   const [userSession, setUserSession] = useState(false);
//   const [user, setUser] = useState(null);
//   // function updateStatus(statusMessage) {
//   //   setStatusMessage(statusMessage);
//   // }
//   useEffect(() => {
//     // Update the document title using the browser API
//     Auth.currentAuthenticatedUser()
//       .then((user) => console.log({ user }))
//       .catch(() => {
//         console.log("Not signed in redirecting to sign in");
//         Auth.federatedSignIn();
//       });
//     const getSession = async () => {
//       await Auth.currentSession().then((userSession) => {
//         setUserSession(userSession);
//       });
//     };
//     getSession();
//   }, []);
//   // }, [tableData]);

//   const cognitoSignOut = () => {
//     Auth.signOut();
//   }

//   if (userSession && userSession?.isValid()) {
//     return (
//       <Fragment>
//         <Header signout={cognitoSignOut} user={user}></Header>
//         {/* <Header></Header> */}
//          <Routes>
//            <Route path="/" element={<Listview />} />
//            <Route path="/create" element={<Create />} />
//            <Route path="/update/:id" element={<Update />} />
//          </Routes>
//         <Footer></Footer>
//       </Fragment>
//     );
//   } else {
//     /* If no valid token exists, the page redirects so you can obtain one */
//     return (
//       <>
//         You will be automatically redirected or{" "}
//         <a href="#" onClick={Auth.federatedSignIn}>
//           click here to login
//         </a>
//         .
//       </>
//     );
//   }
// }

export default App;
