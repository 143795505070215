import { Fragment } from "react";
import { NavLink } from "react-router-dom";
import classes from "./Header.module.css";
import econnLogo from "../../assets/images/econn-red-logo.png";
import { Auth } from "aws-amplify";
import { Button } from "react-bootstrap";

const Header = (props) => {
  return (
    <Fragment>
      <header className={classes.header}>
        <div className={classes["main-image"]}>
          <img src={econnLogo} alt="econnect logo" />
        </div>
        <nav>
          <NavLink activeClassName="active" className="active" to="/">
            <div activeClassName="active">Contents of Manifest</div>
          </NavLink>
          <NavLink activeClassName="active" to="create">
            <div activeClassName="active">Add a new Jnumber</div>
          </NavLink>
          <a activeClassName="active" onClick={() => Auth.signOut()}>
            <div activeClassName="active">Logout</div>
          </a>
          {/* <Button onClick={() => Auth.signOut()}>Logout</Button> */}
        </nav>
      </header>
    </Fragment>
  );
};
export default Header;
