import { useState } from "react";
import { callApi } from "../utilities/utilities";
import classes from "./create.module.css";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Create = () => {
  const [jnumber, setJnumber] = useState("");
  const [featureName, setFeatureName] = useState("");
  const [featureVersion, setFeatureVersion] = useState("");
  const [activationDate, setActivationDate] = useState("");
  const [customerNo, setCustomerNo] = useState("");
  const [status, setStatus] = useState("");

  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/`;
    navigate(path);
  };

  const handleJnumberChange = (e) => {
    setJnumber(e.target.value);
  };

  const handleFeatureNameChange = (e) => {
    setFeatureName(e.target.value);
  };

  const handleFeatureVersionChange = (e) => {
    setFeatureVersion(e.target.value);
  };

  const handleActivationDateChange = (e) => {
    const inputDate = e.target.value
    const year = inputDate.slice(0,4)
    const month = inputDate.slice(5,7)
    const date = inputDate.slice(8,10)
    const requestDate = year.concat(month,date)
    setActivationDate(requestDate);
  };

  const handleCustomerNoChange = (e) => {
    setCustomerNo(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    callApi(
      "/Stage/add_jnumber",
      {
        jnumber: jnumber,
        feature_name: featureName,
        feature_version: featureVersion,
        activation_date: activationDate,
        customer_no: customerNo,
      },
      { "Content-Type": "application/json" },
      "POST"
    ).then((response) => {
      setStatus("created");
      if(response.status !== 200) {
        alert(`${response.data.message}`);
        throw new Error(response.data.message)
      }
      alert(`${response.data.message}`);
      routeChange();
    }).catch((e) => {
      alert("Failed to add Jnumber");
      console.log(e);
      routeChange();
    })
  };

  return (
    <div className={classes.createForm}>
      <header>
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <h2> Add a new Jnumber </h2>
          <table className={classes.formTable}>
            <tr>
              <td className={classes.formLabel}>
                <label>jnumber: </label>
              </td>
              <td className={classes.formInput}>
                <input
                  type="text"
                  onChange={(e) => {
                    handleJnumberChange(e);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td className={classes.formLabel}>
                <label>Feature name: </label>
              </td>
              <td className={classes.formInput}>
                <input
                  type="text"
                  onChange={(e) => {
                    handleFeatureNameChange(e);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td className={classes.formLabel}>
                <label>Feature version: </label>
              </td>
              <td className={classes.formInput}>
                <input
                  type="text"
                  onChange={(e) => {
                    handleFeatureVersionChange(e);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td className={classes.formLabel}>
                <label>Activation date: </label>
              </td>
              <td className={classes.formInput}>
                <input
                  className={classes.datePicker}
                  type="date"
                  onChange={(e) => {
                    handleActivationDateChange(e);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td className={classes.formLabel}>
                <label>Customer no: </label>
              </td>
              <td className={classes.formInput}>
                <input
                  type="text"
                  onChange={(e) => {
                    handleCustomerNoChange(e);
                  }}
                />
              </td>
            </tr>
          </table>
          <br />

          <Button type="submit">Add Jnumber</Button>
        </form>
      </header>
    </div>
  );
};

export default Create;
