import { useEffect, useState } from "react";
import { callApi } from "../utilities/utilities";
import classes from "./update.module.css";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import ReactSelect from "react-select";

const Update = () => {
  const [jnumber, setJnumber] = useState("");
  const [featureName, setFeatureName] = useState("");
  const [featureVersion, setFeatureVersion] = useState("");
  const [activationDate, setActivationDate] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [revoked, setRevoked] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [status, setStatus] = useState("");
  const [jnumbers, setJnumbers] = useState([]);
  const [getJnumber, setGetJnumber] = useState([]);
  const [activation_dayjs, setActivationDayjs] = useState("")
  const [expiration_dayjs, setExpirationDayjs] = useState("")


  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/`;
    navigate(path);
  };

  const id = useParams();
  useEffect(() => {
    setGetJnumber(id);
  }, [])

  const fetchJnumbers = () => {
    let callApiResponse = callApi(
      "/Stage/read_manifest ",
      {},
      { "Content-Type": "application/json" },
      "GET"
    );
    callApiResponse
      .then((response) => {
        // console.log('response???????', response);
        setJnumbers(response.data.Rows);
        })
        .catch((error) => {
          console.log(error);
        })
      };
      // console.log('jnumbers???????', jnumbers);
      // console.log('getJnumber?????',getJnumber);
  useEffect(() => {
    fetchJnumbers();
  }, []);

  const getSingleJnumber = () => {
    const filteredResult = jnumbers.filter((jnumber) => {
      if(jnumber.jnumber.toLowerCase() === getJnumber.id.toLowerCase()) {
        setJnumber(jnumber.jnumber)
        setFeatureName(jnumber.feature_name)
        setFeatureVersion(jnumber.feature_version)

        const activation = jnumber.activation_date
        const activation_year = activation.slice(0,4)
        const activation_month = activation.slice(4,6)
        const activation_date = activation.slice(6,8)
        const outputActivationDate = activation_year.concat(activation_month,activation_date)
        setActivationDate(outputActivationDate)
        const activation_date_dayjs = dayjs(outputActivationDate).format('YYYY-MM-DD')
        setActivationDayjs(activation_date_dayjs)
        console.log(activationDate)

        const expiration = jnumber.expiration_date
        const expiration_year = expiration.slice(0,4)
        const expiration_month = expiration.slice(4,6)
        const expiration_date = expiration.slice(6,8)
        const outputExpirationDate = expiration_year.concat(expiration_month,expiration_date)
        setExpirationDate(outputExpirationDate)
        const expiration_date_dayjs = dayjs(outputExpirationDate).format('YYYY-MM-DD')
        setExpirationDayjs(expiration_date_dayjs)
        console.log(expirationDate)
        
        setCustomerId(jnumber.customer_id)
        setRevoked(jnumber.revoked)
        return jnumber.jnumber
      }
      });
      console.log('filtered result: ', filteredResult)
  }

  useEffect(() => {
    getSingleJnumber()
  }, [jnumbers])

  const handleJnumberChange = (e) => {
    setJnumber(e.target.value);
  };

  const handleFeatureNameChange = (e) => {
    setFeatureName(e.target.value);
  };

  const handleFeatureVersionChange = (e) => {
    setFeatureVersion(e.target.value);
  };

  const handleActivationDateChange = (e) => {
    const inputDate = e.target.value
    const year = inputDate.slice(0,4)
    const month = inputDate.slice(5,7)
    const date = inputDate.slice(8,10)
    const requestDate = year.concat(month,date)
    setActivationDate(requestDate);
    const activation_date_dayjs = dayjs(requestDate).format('YYYY-MM-DD')
    setActivationDayjs(activation_date_dayjs)
  };

  const handleExpirationDateChange = (e) => {
    const inputDate = e.target.value
    const year = inputDate.slice(0,4)
    const month = inputDate.slice(5,7)
    const date = inputDate.slice(8,10)
    const requestDate = year.concat(month,date)
    setExpirationDate(requestDate);
    const expiration_date_dayjs = dayjs(requestDate).format('YYYY-MM-DD')
    setExpirationDayjs(expiration_date_dayjs)
  };

  const handleRevokedChange = (e) => {
    setRevoked(e.target.value);
  };

  const handleCustomerIdChange = (e) => {
    setCustomerId(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    callApi(
      "/Stage/update_manifest",
      {
        "activation_date": activationDate,
        "customer_no": customerId,
        "expiration_date": expirationDate,
        "feature_name": featureName,
        "feature_version": featureVersion,
        "jnumber": jnumber,
        "revoked": revoked
      },
      { "Content-Type": "application/json" },
      "POST"
    ).then((response) => {
          setStatus("updated");
          if(response.status !== 200) {
            alert(`${response.data.message}`);
            throw new Error(response.data.message)
          }
          alert(`${response.data.message}`);
          routeChange()
    }).catch((e) => {
      console.log(e);      
      routeChange();
    })
  }

  const revokedOptions = [
    { label: "Y", value: 'Y' },
    { label: "N", value: 'N' },
  ];

  return (
    <div className={classes.updateForm}>
      {/* <header> */}
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <h2>Update Jnumber </h2>
          <table className={classes.formTable}>
            <tr>
              <td className={classes.formLabel}>
                <label>jnumber: </label>
              </td>
              <td className={classes.formInput}>
                <input
                  disabled
                  type="text"
                  onChange={(e) => {
                    handleJnumberChange(e);
                  }}
                  value={jnumber}
                />
              </td>
            </tr>
            <tr>
              <td className={classes.formLabel}>
                <label>Feature name: </label>
              </td>
              <td className={classes.formInput}>
                <input
                  type="text"
                  onChange={(e) => {
                    handleFeatureNameChange(e);
                  }}
                  value={featureName}
                />
              </td>
            </tr>
            <tr>
              <td className={classes.formLabel}>
                <label>Feature version: </label>
              </td>
              <td className={classes.formInput}>
                <input
                  type="text"
                  onChange={(e) => {
                    handleFeatureVersionChange(e);
                  }}
                  value={featureVersion}
                />
              </td>
            </tr>
            <tr>
              <td className={classes.formLabel}>
                <label>Activation date: </label>
              </td>
              <td className={classes.formInput}>
                <input
                  type="date"
                  className={classes.datePicker}
                  onChange={(e) => {
                    handleActivationDateChange(e);
                  }}
                  value={activation_dayjs}
                />
              </td>
            </tr>
            <tr>
              <td className={classes.formLabel}>
                <label>Expiration date: </label>
              </td>
              <td className={classes.formInput}>
                <input
                  type="date"
                  className={classes.datePicker}
                  onChange={(e) => {
                    handleExpirationDateChange(e);
                  }}
                  value={expiration_dayjs}
                />
              </td>
            </tr>
            <tr>
              <td className={classes.formLabel}>
                <label>Revoked: </label>
              </td>
              <td className={classes.formInput}>
                <select 
                  onChange={(e) => {
                    handleRevokedChange(e);
                  }}
                  value={revoked}>
                  <option>Y</option>
                  <option>N</option>
                </select>
              </td>
            </tr>
            <tr>
              <td className={classes.formLabel}>
                <label>Customer Id: </label>
              </td>
              <td className={classes.formInput}>
                <input
                  type="text"
                  onChange={(e) => {
                    handleCustomerIdChange(e);
                  }}
                  value={customerId}
                />
              </td>
            </tr>
          </table>
          <br />

          <Button type="submit">update Jnumber</Button>
        </form>
      {/* </header> */}
    </div>
  );
};

export default Update;
