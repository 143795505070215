// Globally available constants defined here

const HTTPS = "https://";

export const ACCESS_USER_TYPE = process.env.REACT_APP_ACCESS_USER_TYPE

// Environment Variables
export const APP_URL = HTTPS + process.env.REACT_APP_URL; //"https://imagery.orthofmsdev.com";
export const COGNITO_CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID;
export const HOME_URL = APP_URL; //process.env.REACT_APP_HOME_URL;  // commented since not being used anywhere
export const LOGOUT_URL = APP_URL; //process.env.REACT_APP_LOGOUT_URL;
const COGNITO_DOMAIN = process.env.REACT_APP_COGNITO_DOMAIN;
const API_GATEWAY = process.env.REACT_APP_API_GATEWAY;

// Public Parameters
export const AUTH_TOKEN_COOKIE_NAME = "auth-token";
export const INVALID_TOKEN_COOKIE_NAME = "invalid-token";
export const AUTH_QUERY_PARAM_KEY = "code";

// Private Parameters
const SCOPE = "aws.cognito.signin.user.admin+openid";
const RESPONSE_TYPE = "code";

// URLs
export const LOGIN_URL =
  COGNITO_DOMAIN +
  "/oauth2/authorize?client_id=" +
  COGNITO_CLIENT_ID +
  "&response_type=" +
  RESPONSE_TYPE +
  "&redirect_uri=" +
  APP_URL +
  "&scope=" +
  SCOPE;

export const AUTH_TOKEN_URL = COGNITO_DOMAIN + "/oauth2/token";
export const CONFIG_API_URL = HTTPS + API_GATEWAY;
export const COGNITO_LOGOUT_URL =
  COGNITO_DOMAIN +
  "/logout?client_id=" +
  COGNITO_CLIENT_ID +
  "&logout_uri=" +
  APP_URL;
